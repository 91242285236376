@import "utils";
//--------------------part public------------------

$icon-arrow-height-svg : 8px;
$icon-arrow-color : #A4AFB7;
$height-box-input : 32px;
$font-family: 'Open Sans', sans-serif;
$font-weight-bold: bold;
$font-weight-regular: 400;
$font-weight-light: 200;
$button-color-red : #FF6565;
$text-color: #787878;
$button-color-green : #4AD991;

@mixin row(){
  padding-right: 32px;
  padding-top: 15px;
  width: 100%;
  float: left;
  display: flex;
  padding-left: 32px;
}

.row {
  @include row;
}

@mixin shadow-body(){
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.shadow-body{
  @include shadow-body;
}

.inventory-content{
  .container-center {
    padding: 80px 25px 0 25px;
  }

  .body-car-inventory{
    width: 100%;
    float: left;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    .body-car-header {
      width: 100%;
      height: 56px;
      border-bottom: 1px solid #EFEFEF;

      .content {
        display: flex;
        padding: 10px 0 0 15px;

        .action {
          margin-right: 15px;

          svg {
            fill: #4ad991;
            cursor: pointer;
          }
        }

        .panel-filter{
          .rowFilter {
            .col-left {
              display: flex;

              .category-status, .category-locations {
                @include border-box;
                height: 32px;
                margin-right: 20px;
                min-width: 151px;
                margin-top: 3px;
                width: 190px;

                label {
                  font-weight: 400;
                }

                .pill-container-group, .pill-container {
                  label {
                    color: #4D4F5C;
                    font-weight: 400;
                  }
                }
                .pill-locations-group{
                  width: 80px;
                }
              }
              .show-inactive{
                width: 160px;
                border: 1px solid #d7dae2 !important;
                float: left;
                margin: 3px 0;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                color: #ff6565;
                background: #fff;
                cursor: pointer;
              }

              .ic-show-inactive {
                width: 13px;
                margin: 8px 8px 0 0;
                .a {
                  fill: #ff6565;
                }
              }

              .ic-hide-inactive {
                width: 13px;
                margin: 8px 8px 0 0;
                fill: #fff;
              }

              .hide-inactive{
                width: 160px;
                float: left;
                margin: 3px 0;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                color: #fff;
                background-color: #ff6565;
                border: none !important;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .container-cars{
      div{
        .header-status{
          font-size: 16px;
          display: flex;
          align-items: center;
          padding: 20px 20px 0 20px;
          .vertical-bar{
            width: 6px;
            height: 16px;
          }
          .title-status{
            padding-left: 5px;
            font-size: 15px;
            color: $text-color;
            font-weight: 700;
          }
        }
        .container-cars-grid{
          .photoList{
            div{
              .car-image-row{
                .car-image-column{
                  .info-car{
                    display: grid;
                    padding-bottom: 4px;
                    .label-car-information{
                      @include one-single-line-text;
                    }
                  }
                  .bottom-car{
                    .select{
                      .btn-drop-down-container{
                        .btn-drop-down{
                          .label-value{
                            width: calc(100% - 24px);
                            @include one-single-line-text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #containerHeaderGeneral{
    //height: auto;
    justify-content: space-between;
    flex-wrap: wrap;
    .left-container{
      .action{
        padding-left: 15px;
      }
      .title{
        margin-left: 0;
        padding-left: 10px;
      }
    }
    .container-filters{
      background-color: #FFF;
    }
  }
}

.copied-row-alert {
  float: left;
  margin: 3px 20px;
  height: 32px;
  color: #fff;
  background-color: #343a40;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 120px;
}

.container-header {
  .container-filters {
    .panel-filter {
      width: 100%;
      float: left;
      background-color: #fff;
      height: auto;
      padding-left: 17px;
      padding-right: 30px;

      .row-filters-expanded,.row-first-filters-mobile,.row-second-filters-mobile,.row-clean-filter{
        display: none;
      }

      .rowFilter {
        width: 100%;
        float: left;
        display: flex;
        padding-left: 20px;
        height: 32px;

        .col-left, .col-middle {
          width: 32.3%;
          margin-right: 1.5%;
          display: flex;

          @mixin box-left(){
            width: 50%;
            margin-right: 20px;
          }
          .box-left {
            @include box-left;
          }
          .box-right {
            @include box-left;
            margin-right: 0 !important;

            .disabled {
              background: #F5F6FA;
              cursor: not-allowed;
            }
          }
        }

        .col-right {
          width: 32.3%;
          display: flex;
          justify-content: flex-end;
        }

        .check-box {
          padding-top: 3px;
          width: auto !important;
        }
        .icon-add-task {
          margin-right: 12px;
          margin-top: -1px;
          display: flex;
          width: 40px;
          height: 40px;
          svg {
            width: 40px;
            height: 40px;
            fill: #4ad991;
          }

          &:hover{
            cursor: pointer;
          }
        }
        .apply {
          width: 7%;
          height: 31px;
          padding: 5px 12px;
        }

        .reset-all-filters{
          height: 100%;
          padding: 5px 15px;
          border: 1px solid #2194F1;
          cursor: pointer;
          border-radius: 4px;

          .icon-reset-content{
            align-items: center;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            div:hover{
              cursor: pointer;
            }
          }
          .icon-reset{
            fill: #A4AFB7;
            height: 20px;
            width: 20px;
            display: flex;
            &:hover{
              cursor: pointer;
            }
          }
          a{
            color: #2194F1;
            label{
              cursor: pointer;
              font-weight: lighter;
            }
          }
        }
        .category, .category-status{
          &:hover{
            cursor: pointer;
          }
        }
        .category, .category-disabled{
          width: 190px ;
        }
        #selectedMakes,#selectedModels{
          width: 100% ;
          min-width: 156px;
        }
        #year{
          width: 114px ;
          min-width: 114px;
        }
        #mile{
          width: 172px ;
          min-width: 172px;
        }
        #price{
          width: 145px ;
          min-width: 145px;
        }
        .category, .category-status, .category-disabled, .category-locations{
          @include border-box;
          height: $height-box-input;
          margin-right: 5px;
          i {
            padding-left: 25px;
            display: inline-table;
            font-size: 22px !important;
            cursor: pointer;
          }

          label{
            color: #4D4F5C;
            font-weight: $font-weight-regular;
            &:hover{
              cursor: pointer;
            }
          }

          .icon-arrow-content{
            float: right;
            padding: 1px 1px 1px 0;
          }

          .icon-arrow-expand{
            height: $icon-arrow-height-svg;
            font-weight: 900;
            fill: $icon-arrow-color;
          }
        }

        .pill-locations-group{
          width: 80px;
        }

        @mixin category-status(){
          width: 100%;
          min-width: 151px;
        }
        .category-status{
          @include category-status;
        }
        .category-locations{
          @include category-status;
          margin-right: 0 !important;
        }

        .pagination-filter {
          margin-left: 120px;
          .container-pagination {
            padding: 0;
            margin-top: -1px;
          }
        }

        .category-disabled{
          border: 1px solid #ccc;
          label{
            color: #ccc !important;
            font-weight: $font-weight-regular;
            &:hover{
              cursor: default;
            }
          }
          .icon-arrow-expand{
            height: $icon-arrow-height-svg;
            fill: #ccc !important;
          }
        }
      }

      .row-filters-expanded {
        padding-top: 15px;
        width: 100%;
        float: left;
        display: flex;
        padding-left: 20px;

        .container-filter-year, .container-filter-price {
          width: 32.3%;
          padding: 1%;
          border: 1px solid #ccc;
          border-radius: 4px;
          margin-right: 1.5%;
        }

        .container-filter-mile {
          width: 32.3%;
          padding: 1%;
          border: 1px solid #ccc;
          border-radius: 4px;
        }

      }

      .row-first-filters-mobile,.row-second-filters-mobile,.row-clean-filter{
        display: none;
      }
    }
  }
}

.container-center{
  min-height: 548px;
  padding: 25px 30px 0 50px;
  float: left;
  width: 100%;

  .container {
    width: 100%;
    background-color: white;
    padding: 0;
  }
  .navbar {
    margin-bottom: 0;
  }
  .navbar-default {
    background-color: white;
    border-color: white;
  }
  .navBarFilter {
    width: 100%;
    float: left;
    padding-left: 10px;
    padding-right: 0;
  }

  .container-filters{
    padding: 0 !important;
    @include col100;
  }

  .container-cars{
    width: 100%;
    float: left;
    padding-bottom: 1%;
  }
}

.container-cars-grid {
  float: left;
  width: 100%;
  padding: 20px 0 0 20px;
}

.car-pagination {
  display: none;
}

.icon-toggle-horizontal-line{
  width: 15px;
  height: 1px;
  background-color: #FFFFFF;
  margin:auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.icon-toggle-vertical-line{
  width: 1px;
  height: 15px;
  background-color: #FFFFFF;
  margin:auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.box-edit-car{
  @include col100;
  @include shadow-body;

  .car-header{
    display: flex;
    align-items: center;
    padding: 0 15px 0 15px;

    h5{
      padding: 0 0 0 15px;
      margin: 0;
      font-weight: $font-weight-bold;
      font-size: 21px;
      color: #787878;
      font-family: $font-family;
    }

    .icon-question-content{
      cursor: pointer;
      margin-left: 8px;
      margin-top: 5px;
    }
  }

  .applicant-section-header{
    width: 100%;
    margin-left: 16px;
    display: inline-block;

    .applicant-section-header-info{
      float:left;
      margin-left: 12px;

      .icon-question-content{
        cursor: pointer;
        margin-left: 8px;
        margin-top: 5px;
      }
    }

    .applicant-section-header-subtitle{
      font-size: 13px;
      font-family: $font-family;
      color:#515151;
      font-weight: 500;
    }
  }

  .applicant-section-header-title{
    font-family: $font-family;
    font-size: 15px;
    font-weight: $font-weight-bold;
    color:#515151;
  }

  .applicant-section-header-icon{
    background-color: #2194F1;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    float: left;
    position: relative;
    cursor: pointer;
  }

  .car-images{
    @include col100;
    padding: 20px 0 20px 16px;
    border-bottom: 1px solid #EFEFEF;
    background-color: #fff;

    .container-btn-save{
      float: right;
      padding: 10px 35px;

      .btn-save-car{
        float: right;
        color: #ffffff;
        border-radius: 4px;
      }
    }
  }

  .car-documents {
    @include col100;
    padding: 20px 0 20px 16px;
    border-bottom: 1px solid #EFEFEF;
    background-color: #fff;
    .body-documents {
      float: left;
      width: 100%;
      padding: 15px 15px 15px 20px;
      .col-100 {
        float: left;
        width: 100%;
        margin-bottom: 30px;
        .upload-left{
          float: left;
          width: 27%;
          .container-title-document {
            display: flex;
            .title {
              color: #a4a4a4;
              width: 100%;
              margin-top: -3px;
            }
            .box-switch {
              float: right;
              display: block;
            }
          }
          .upload-document-car {
            outline: none;
            margin-top: 6px;
            .upload-text {
              .label-upload-text {
                font-size: 12px;
                .sub-text {
                  color: #4aa6f3;
                }
              }
            }
          }
        }
        .upload-right{
          display: flex;
          float: right;
          width: 71%;
          .files-container{
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .document-item{
              width: 237px;
              // height: 133px;
              margin-right: 15px;
              // margin-bottom: 15px;
              border-radius: 3px;
              // border: 1px solid #D7DAE2;
              .icon-file-container{
                // border: 1px solid #D7DAE2;
                width: 100%;
                border-radius: 5px;
                @include item-center;
                .icon-file-parent{
                  .icon-file{
                    width: 20px;
                    height: auto;
                  }
                }
                img{
                  width: 100%;
                  height: 152px;
                  object-fit: cover;
                  border-radius: 5px;
                }
              }
              .file-info-container{
                display: flex;
                width: 100%;
                margin-bottom: 7px;
                .file-info-container-text{
                  width: 85%;
                  float: left;
                  display: flex;
                  flex-direction: row;
                  padding: 5px 0 5px 0;
                  .file-weight{
                    color: #787878;
                  }
                  .title{
                    color: #787878;
                    margin-left: 6px;
                  }
                  .icon-type-photo{
                    height: 18px;
                    width: 18px;
                    fill: #2294f1;
                  }
                  .icon-type-document{
                    height: 18px;
                    width: 20px;
                    fill: #ff6566;
                  }
                }
                .file-info-container-icon{
                  float: right;
                  display: flex;
                  margin-bottom: 5px;
                  .icon-delete-cont{
                    align-self: flex-end;
                    height: 15px;
                    margin-bottom: 10px;
                    float:left;
                    margin-left: 10px;

                    .icon-delete{
                      width: 15px;
                      height: auto;
                      margin-top: 2px;
                      margin-right: 2px;
                      fill: #ff6565;
                    }
                    &:hover{
                      cursor: pointer;
                    }
                    .icon-download{
                      width: 15px;
                      height: auto;
                      margin-top: 2px;
                      margin-right: 2px;
                      fill: #2194f1;
                    }
                    &:hover{
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .car-marketing{
    @include col100;
    padding: 20px 0 20px 16px;
    background-color: #fff;
    .container-icons{
      height: auto;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-left: 10px;
      .container-logo{
        width: 160px;
        height: 67px;
        margin: 20px 5px;
        float: left;
        .container-icon{
          height: 50px;
          width: 160px;
          border: 1px solid #EFEFEF;
          padding: 10px;
          border-radius: 4px;
          //--margin: 3px 3px 0 3px;
          @mixin marketing-icon-svg(){
            height: auto;
            width: 140px;
            cursor: pointer;
          }
          .marketing-icon-svg{
            @include marketing-icon-svg;
          }
          .unselected-marketing-icon-svg{
            @include marketing-icon-svg;
            path {
              fill: #A4AFB7;
            }
          }
          .selected-marketing-icon-svg{
            @include marketing-icon-svg;
            path {
              fill: #2194f1;
            }
          }
        }
        .upload-text {
          width: 100%;
          text-align: left;
          padding-top: 0;
          padding-bottom: 10px;
          margin-left: 5px;
          .icon-info{
            width: 11px;
            height: 11px;
          }
          .label-upload-text {
            font-size: 9px;
            font-family: $font-family;
            font-weight: $font-weight-light;
            .sub-text {
              color: #4aa6f3;
              cursor: pointer;
            }
          }
          .label-upload-text.active{
            color: #4ad991;
          }
        }
      }
    }
  }
}

.body-edit-car {
  @include col100;
  padding: 80px 30px 30px 30px;
  position: relative;
  .cnic-container-buttons {
    position: fixed;
    right: 70px;
    display: flex;
    top: 160px;
    z-index: 1;
    .add-car-to-inventory{
      border: 1px solid #D7DAE2;
      background-color: white;
      border-radius: 4px;
      height: 32px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin: 0 10px;
      label{
        color: $text-color;
        font-size: 12px;
        margin-bottom: 0;
        cursor: pointer;
      }
      .add-car-img{
        width: 21px;
        height: 21px;
        margin-right: 8px;
      }
      .icon-arrow-content{
        float: right;
        padding: 0 0 0 10px;
        .icon-arrow-expand{
          height: $icon-arrow-height-svg;
          fill: #A4AFB7;
        }
      }
      &:hover{
        cursor: pointer;
      }
      .content-check{
        display: flex;
        align-items: center;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        height: auto;
        margin-top: 5px;
        .checkbox{
          width: 20px !important;
          float: left;
          margin-top: 0;
          margin-bottom: 0;
        }
        .container-label{
          display: flex;
          align-items: center;
          .check-description{
            font-size: 13px;
            color: $text-color;
            margin-top: 5px;
            float: left;
            font-weight: $font-weight-regular;
          }
          .check-required{
            float: left;
            color: $button-color-red;
            margin: 0;
            font-weight: 600;
          }
        }
      }
    }
    .check-ark{
      margin-right: 10px;
      .icon-check{
        border-radius: 50%;
        height: 40px;
        width: 40px;
        background-color: #4AD991;
        border-color: #4AD991;
        div{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          svg{
            height: 18px;
          }
        }
      }
      &:hover{
        cursor: pointer;
      }
    }
    .button-save{
      @include buttons-next-done;
      border: 1px solid $button-color-green;
      background-color: $button-color-green;
      &:focus{
        border: 1px solid $button-color-green !important;
      }
    }

    .save{
      margin-right: 10px;
    }
    svg{
      height: 18px;
      fill: white;
    }
  }
  .btn-tab {
    span {
      margin-top: 10px;
      float: left;
      margin-left: 8px;
    }
  }

  a:hover {
    cursor: pointer;
    text-decoration: none;
    color: #fff;
  }
  .btn-tab-pricing {
    width: 70px;
    height: 50px;
    top: 80px;
    position: absolute;
    left: 50px;
  }
  .btn-tab-image {
    width: 70px;
    height: 50px;
    top: 80px;
    position: absolute;
    left: 50px;
  }
  .btn-tab-general {
    left: 50px;
    border: 0;
    width: 70px;
    height: 50px;
    position: absolute;
  }
  .car-info, .car-image, .car-pricing, .car-cost {
    background: white;
    float: left;
    width: 100%;
    padding: 20px 0 20px 15px;
    border-bottom: 1px solid #EFEFEF;
    .header-car-info {
      display: flex;
      background: white;
      form {
        width: 100%;
      }
    }
    .body-car-info, .bottom-car-info {
      display: flex;
      form {
        width: 100%;
        .panel-body {
          padding-bottom: 2px;
        }
      }
    }
    .bottom-car-info {
      padding-bottom: 25px;
      float: left;
      width: 100%;
    }
    h5{
      padding: 0 0 0 15px;
      margin: 0;
      font-weight: $font-weight-bold;
      font-size: 21px;
      color: #787878;
      font-family: $font-family;
    }

    .container-message-info {
      padding: 10px 31px 10px 10px;
      @include col100;
      .message-info{
        width: 100%;
        float: left;
        .icon-info{
          width: 20px;
          height: 20px;
        }
      }

      .btn-new-sale{
        float: left;
        padding: 0 15px;
        button{
          height: 42px;
          border-radius: 5px;
          background-color: #FF6565;
          border-color: #FF6565;
        }
      }
    }
    .container-message-info-not-inventory {
      padding: 0 20px 10px 10px;
      float: left;
      width: 100%;
      .message-info-not-inventory{
        width: calc(100% - 310px);
        height: 47px;
        float: left;
        display: flex;
        align-items: center;
        border-radius: 10px;
        padding-left: 10px;
        .icon-info{
          width: 20px;
          height: 20px;
        }
        label{
          margin-bottom: 0;
          margin-left: 10px;
        }
      }
    }
  }

  .car-image {
    .header-image {
      float: left;
      position: relative;
      width: 100%;
      display: flex;
      padding: 10px 0 0 20px;

      .name {
        width: 160px;
        font-size: 20px;
        font-weight: 500;
        float: left;
        color: #787878;
      }

      .image-button {
        padding: 5px 0;
        button {
          width: 20px;
          height: 20px;
          padding: 0;
        }
      }
    }
    .body-car-image {
      float: left;
      position: relative;
      width: 100%;
      display: flex;
      padding: 10px 0 0 20px;
    }
  }

  .body-car-mechanical {
    background: white;
    float: right;
    padding: 20px 0 20px 16px;
    width: 100%;
    border-bottom: 1px solid #EFEFEF;
    h5 {
      padding: 0 0 0 15px;
      margin: 0;
      font-weight: $font-weight-bold;
      font-size: 21px;
      color: #787878;
      font-family: $font-family;
    }
    form {
      width: 100%;
    }
    .repairs {
      float: left;
      position: relative;
      width: 100%;
      padding-top: 15px;
      .header-repair {
        display: flex;
        padding-left: 28px;
        padding-bottom: 10px;
        width: 100%;
        .name {
          width: 80px;
          font-size: 20px;
          font-weight: 500;
          float: left;
          color: #787878;
        }
        .repair-button {
          padding: 5px 0;

          button {
            width: 20px;
            height: 20px;
            padding: 0;
          }

        }
      }
      .body-repair {
        display: flex;
        width: 96%;
        position: relative;
        padding-left: 8px;
        float: left;
      }
    }

    .body-car-mechanical-info{
      display: flex;
      form {
        width: 100%;
        .panel-body {
          padding-bottom: 2px;
        }
      }
    }
  }

  .car-pricing{
    .body-car-pricing-info{
      display: flex;
      form {
        width: 100%;
        .panel-body {
          padding-bottom: 2px;
        }
      }
    }

    .col25{
      .classInputNewCar{
        .MuiInput-underline:before, .MuiInput-underline:after{
          display: none;
        }
      }
    }
  }

  .cloud-upload-content-inventory{
    margin-bottom: -10px;
    width: 10%;
  }

  .cloud-upload-document-inventory{
    margin-bottom: -10px;
    svg{
      width: 45px;
      height: 45px;
    }
  }

  .document-inventory-disabled{
    padding: 20px;
    svg{
      fill: #d2d5d7;
      height: 45px;
      width: 45px;
    }
  }

  .cloud-upload-inventory {
    fill: #A4AFB7;
  }
  .save-mobile-view{
    background-color: white;
    width: 100%;
    display: none;
    height: 50px;
    align-items: center;
    justify-content: flex-end;
    .option-edit{
      margin-right: 33px;
      .btn-save-mobile{
        float: left;
        color: #ffffff;
        border-radius: 5px;
        border-color: transparent;
        height: 30px;
        width: 60px;
      }
    }
  }

  .loader-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    //left: 0;
    top: 60px;
    //bottom: 0;
    //right: 0;
    background: white;
    z-index: 200;
  }

  .form-control{
    border: 1px #dddee0 solid;
    box-shadow: unset;
    border-radius: 3px;
  }
}

.car-info-inventory{
  .images-left-inventory{
    float: left;
    width: 40%;
    padding: 0 0 10px 20px;

    .image-container-inventory{
      background-color: #ffffff;
      width: 100%;
      @include item-center;

      .without-images{
        height: 160px;
        background-color: #F5F6FA;
        @include item-center;
        .title-container-without-images{
          color:#A4AFB7;
          font-size: 15px;
          padding: 0 150px 0;
        }
      }
      .current-image{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .image-deleted{
        @include col100;
      }

      .image-box-deleted{
        @include item-center;
        width: 100%;
        height: 100%;
        background-color: #efefef;
        border-radius: 10px;
        color: #A4AFB7;
        font-size: 20px;
        font-weight: 900;
      }
    }

    .radio-button-cover-image{
      div>div>div>svg{
        fill: #3B86FF !important;
      }
      div>label{
        color: #787878 !important;
        font-size: 11px !important;
      }
      div>div{
        margin-right: 4px !important;
      }
    }
    .title-cover-image{
      font-family: Source Sans Pro;
      font-size: 10px;
      padding: 0 0 0 5px;
      color: #787878;
      font-weight: 100;
    }

    .options-image{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 10px 0 10px 0;

      .options-inventory{
        padding-left: 15px;
      }

      .options-inventory:hover{
        cursor: pointer;
      }

      .options-upload-inventory{
        height: 20px;
        width: 20px;
        fill: #A4AFB7;
      }

      .image-alert-deleted{
        float: left;
        display: flex;
        align-items: center;
        width: 100%;

        label{
          margin: 0;
          padding-left: 5px;
          color: #787878;
        }
      }
    }

    .check-box-container{
      @include col100;
      padding-top: 15px;

      .container-check{
        margin-left: 15px;
      }
    }
  }


  .images-right-inventory{
    float: right;
    width: 60%;
    padding: 10px 20px 10px 60px;

    .uploaded-images{
      display: flex;

      .droppable-container{
        width: 100%;
        display: flex;
        padding: 0;
        margin: 0;
        .container-image{
          padding: 0 10px 10px 0;
        }
      }

      .current-image-container{
        @include item-center;
        width: 32.3%;
        padding: 2px;
        margin-right: 1%;
        margin-bottom: 8px;
        background-color: #ffffff;

        .current-image{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .image-box{
        @include item-center;
        width: 25%;
        padding: 0 10px 10px 0;
        background-color: #ffffff;
        position: relative;
        .current-image{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .drop-zone-container{
      padding-top: 8px;
    }
    .upload-image-logo {
      cursor: pointer;
      width: 100%;
      //--height: 100%;
      border: 1px dashed $icon-arrow-color;
      background-color: #F5F6FA;
      @include item-center;
      .container-images {
        .cloud-upload-content {
          text-align: center;
          //--margin-bottom: -10px;
        }
        .cloud-upload {
          width: 24px;
          height: 24px;
          fill: #a9aeb2;
          &:hover {
            cursor: pointer;
          }
        }
        .label-upload-logo {
          color : #a9aeb2;
          font-weight: $font-weight-light;
          font-size: 12px;
          font-family: $font-family;
          margin-top: 5px;
          .label-upload-file{
            color: #2194F1;
            text-decoration: underline;
            font-family: $font-family;
            font-size: 12px;
            font-weight: $font-weight-light;
          }
        }
        .label-upload{
          //--padding-top: 10px;
          font-size: 12px;
          text-decoration: underline;
          color: #a9aeb2;
          font-weight: $font-weight-light;
          font-family: $font-family;
        }
        .label-upload-file{
          text-align: center;
          button{
            width: 90%;
            background-color: $light-blue-color-1;
            border-color: $light-blue-color-1;
          }
        }
        .label-upload-more-info{
          color: $light-blue-color-1;
          margin-left: 2px;
          font-weight: 400;
          margin-top: 5px;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .label-upload-mobile{
        display: none;
      }
    }

  }
}

//.container-header{
//  height: auto;
//  display: block;
//  .right-container{
//    float: right;
//  }
//}

.container-component{
  .inventory-content{
    .container-center{
      .container-cars{
        .table-cars{
          .table{
            thead tr th{
              padding-left: 5px;
            }
            tbody{
              tr{
                td{
                  padding: 20px 5px;
                }
                .td-options{
                  min-width: 100px;
                  .options{
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                  }
                }
                .suggPrice, .rPrice{
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal-data-import{
  @include item-center;
  display: flex !important;
  .modal-dialog{
    width: 1180px;
    height: 720px;
  }

  .modal-content{
    width: 100%;
    float: left;
    border-radius: 5px;
    box-shadow: 0 0 30px #00000029;
    border: none;
  }

  .modal-body{
    min-height: 300px;
    float: left;
    width: 100%;
    padding: 0;
    @include item-center;
  }

  .panel-container{
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 5px;

    .icon-close-content{
      height: 32px;
      position: absolute;
      top: -15px;
      right: -15px;
      .cls-1 {
        fill: $button-color-red;
      }
    }

    .icon-close{
      width: 32px;
      height: 32px;
      fill: #ff565e;
    }

    .icon-close:hover{
      cursor: pointer;
    }

    .icon-cash{
      position: absolute;
      bottom: -5px;
      right: 0;
    }
    .icon-cash-svg{
      height: 144px;
      width: 161px;
    }

    .panel-container-body{
      float: left;
      width: 100%;
      height: 100%;
      .panel-top{
        padding: 8px 10px;
        height: 35px;
        label{
          font-size: 15px;
          margin: 0;
          font-weight: $font-weight-bold;
          color: $text-color;
        }
      }
      .panel-middle{
        height: 625px;
        border-top: 1px solid #D7DAE2;
        overflow: auto;
        .panel-container-headers{
          width: 100%;
          padding: 8px 4px 2px 0;
          height: 42px;
          .container-headers{
            display: flex;
            float: left;
            padding-left: 100px;
            padding-bottom: 5px;
            .header-select-assigned, .header-select-assigned-view{
              border: 1px solid #ccc;
              border-radius: 4px;
              height: 32px;
              width: 152px;
              padding: 4px 1px 4px 4px;
              margin: 0 4px;
              position: relative;
              .header-label{
                font-size: 12px;
                color: #4D4F5C;
                font-family: $font-family;
                font-weight: $font-weight-regular;
                padding-left: 5px;
              }
            }
            .header-select-assigned{
              .icon-arrow-content{
                float: right;
                padding: 1px 5px;
                .icon-arrow-expand{
                  height: $icon-arrow-height-svg;
                  fill: #A4AFB7;
                }
              }
              &:hover{
                cursor: pointer;
              }
              label:hover{
                cursor: pointer;
              }
              label{
                color: #4D4F5C
              }
            }
            .header-select-assigned-view{
              background-color: #F5F6FA;
            }
            .pill-container-inventory{
              @include one-single-line-text;
              display:inline-block;
              color: #4D4F5C;
            }
          }
        }
        .panel-container-rows{
          width: 100%;
          height: 588px;
          padding: 5px 0;
          .panel-rows{
            width: 100%;
            height: 100%;
            .rows{
              display: flex;
              float: left;
              .container-number-row{
                width: 100px;
                height: 25px;
                border-top: 1px solid #D7DAE2;
                @include item-center;
                label{
                  margin: 0;
                  font-weight: $font-weight-regular;
                  font-size: 13px;
                }
                .container-icon-import, .container-icon-import-message{
                  float: left;
                  padding-left: 8px;
                  position: relative;
                  .message-error-import, .message-updated-import{
                    display: none;
                  }
                  .icon-check{
                    div{
                      height: 100%;
                      display: flex;
                    }
                    .icon-check-svg{
                      width: 15px;
                    }
                    .icon-check-success-svg{
                      width: 15px;
                      fill: #4ad991;
                      .cls-1 {
                        fill: #4ad991;
                      }
                    }
                    .icon-updated-svg{
                      width: 16px;
                      height: 18px;
                      margin-top: 4px;
                    }
                  }
                }
                .container-icon-import-message{
                  &:hover{
                    cursor: pointer;
                    .message-error-import, .message-updated-import{
                      display: block;
                      position: absolute;
                      left: 30px;
                      top: 0;
                      background: #FFF;
                      color: $text-color;
                      font-size: 11px;
                      border: 1px solid $text-color;
                      border-radius: 4px;
                      height: 25px;
                      width: 400px !important;
                      padding: 3px 5px;
                      label{
                        font-size: 12px;
                        font-weight: $font-weight-regular;
                        width: calc(100% - 2px);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        margin: 0;
                      }
                    }
                    .message-updated-import{
                      width: 455px !important;
                    }
                  }
                }

              }
              .container-data-row{
                width: 160px;
                height: 25px;
                border-top: 1px solid #D7DAE2;
                border-left: 1px solid #D7DAE2;
                display: flex;
                align-items: center;
                padding-left: 10px;
                label{
                  margin: 0;
                  font-weight: $font-weight-regular;
                  font-size: 13px;
                  width: calc(100% - 5px);
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
      .panel-bottom{
        float: left;
        width: 100%;
        height: 60px;
        border-top: 1px solid #D7DAE2;
        padding: 10px 20px;

        .dp-pagination-container{
          float: left;
          .pagination-right{
            padding-left: 0;
            .btn-back, .btn-center, .btn-next{
              float: left;
            }
            .btn-back{
              border-radius: 5px 0 0 5px;
            }
            .btn-next{
              border-radius: 0 5px 5px 0;
            }
            .btn-center{
              border-top: 1px solid #D7DAE2;
              border-bottom: 1px solid #D7DAE2;
              border-right: none;
              border-left: none;
            }
          }
        }

        .container-button-done{
          position: relative;
          .btn-import, .btn-done{
            position: absolute;
            right: 0;
            height: 32px;
            border-radius: 4px;
            background-color: #2194F1;
            border: 1px solid #D7DAE2;
            color: #ffffff;
            width: 114px;
            font-size: 13px;
            font-weight: $font-weight-regular;
            font-family: $font-family;
          }
          .btn-done{
            background-color: #4ad991;
          }
        }
      }
      .container-title{
        @include item-center;
        width: 100%;
        margin-bottom: 30px;
        .title{
          font-family: $font-family;
          color:#787878;
          font-size: 21px;
          font-weight: 600;
        }
      }

      .container-icon{
        float: left;
        padding-left: 10px;
        @include item-center;
        height: 34px;
        .icon-help{
          height: 20px;
          margin-right: 10px;
          &:hover{
            cursor: pointer;
          }
        }

        .icon-help-svg{
          width: 20px;
          height: 20px;
          &:hover{
            circle {
              fill: #787878;
            }
          }
        }

        .help{
          font-size: 13px;
          color: #A4AFB7;
          font-weight: $font-weight-regular;
          margin: 0;
        }
      }

      .container-results{
        float: right;
        padding-right: 140px;
        @include item-center;
        height: 34px;
        .icon-check{
          float: left;
          padding-right: 2px;
          padding-left: 10px;
          height: 20px;
          div{
            height: 100%;
            display: flex;
          }
          .icon-check-svg{
            width: 20px;
            &:hover{
              cursor: pointer;
            }
          }
          .icon-check-success-svg{
            width: 20px;
            fill: #4ad991;
            .cls-1 {
              fill: #4ad991;
            }
            &:hover{
              cursor: pointer;
            }
          }
          .icon-updated-svg{
            width: 22px;
            height: 22px;
            &:hover{
              cursor: pointer;
            }
          }
        }
        label{
          font-size: 13px;
          font-weight: $font-weight-regular;
          color: #787878;
          margin: 0;
        }
        .label-imported{
          padding-left: 8px;
          &:hover{
            cursor: pointer;
            color: #2194F1;
          }
        }
      }
    }
  }
  .popover-csv{
    height: 115px;
    width: 275px !important;
    padding: 15px;
    label{
      font-size: 12px;
      font-weight: $font-weight-regular;
    }
  }
  .popover-error{
    height: 25px;
    width: 400px !important;
    padding: 5px;
    display: flex;
    align-items: center;
    label{
      font-size: 12px;
      font-weight: $font-weight-regular;
      width: calc(100% - 2px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
    }
  }
}

.icon-message{
  @include item-center;
  background-color: #ffffff;
  width: max-content;
  position: absolute;
  left: -30px;
  top: 40px;
  height: 25px;
  border-radius: 5px;
  color: black;
  padding: 0 10px;
  display: none;
}

.loader-container-inventory{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  background: white;
  z-index: 500;
  left: 0;
  top: 0;
  opacity: 1;
  border-radius: 5px;

  .loader-inventory {
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: adjust-hue 1s linear infinite;
  }

  @keyframes adjust-hue {
    0% { transform: rotate(180deg); }
    100% { transform: rotate(360deg); }
  }
}

.pill-container-inventory{
  width: 115px;

  .label-value{
    font-size: 12px;
    position: initial;
    padding-left: 2px;
    color: #4D4F5C;
    font-family: $font-family;
    font-weight: $font-weight-regular;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 5px);
  }
}

.modal-images-info{
  background-color: #2d538a63;
}

/* ********* Media Queries ********* */

@media screen and (max-width: 1400px){
  .inventory-content .container-center {
    padding: 85px 40px 0 40px;
  }

  .container-center .panel-filter .rowFilter .reset-all-filters a label {
    font-size: 10px;
  }

  .car-info-inventory .images-right-inventory .upload-image-logo{
    .container-images{
      .cloud-upload-content{
        padding-top: 4px;
        display: flex;
        width: 100%;
        justify-content: center;
        >div{
          display: flex;
        }
      }
      .label-upload-file{
        label, button{
          font-size: 11px;
          margin-top: 0;
        }
      }
    }
  }

}

@media screen and (max-width: 1360px) {
  .inventory-content{
    .container-header {
      .right-container {
        .mode-view {
          button {
            display: none
          }
          #buttonOption{
            display: block;
            padding-right: 45px;
          }
        }

      }
      .container-filters .panel-filter{
        padding-right: 25px;
      }
    }
    .container-center{
      padding: 80px 20px 0 20px;

      .body-car-inventory{
        .container-filters{
          .panel-filter{
            .rowFilter{
              padding-right: 20px;
              padding-left: 20px;
              .category-status ,.category-locations{
                width: 24%;
                margin-right: 1%;
              }
              .select-categories{
                padding: 0;
                width: 50%;
                .category{
                  width: 49%;
                  margin-left: 2%;
                  margin-right: 0;
                }
                #year,#mile,#price{
                  display: none;
                }
                .reset-all-filters{
                  display: none;
                }
              }
            }
            .row-clean-filter{
              .category,.category-disabled{
                display: none;
              }
              @include row;
              a{
                width: 100%;
                label{
                  cursor: pointer;
                  color: #8c7f7f;
                  text-decoration: underline;
                  float: right;
                }
              }
            }
          }

        }
        .container-cars{
          .container-cars-grid{
            .photoList{
              .car-image-column{
                .info-car{
                  display: inline-block;
                  .label-car-information{
                    width: 100%;
                    color: #787878;
                  }
                  .options{
                    display: none;
                  }
                  .info-attribute-car{
                    display: block;
                    label{
                      display: block;
                      font-weight: 500;
                      color: #787878;
                    }
                  }
                }
                .bottom-car{
                  .select{
                    width: 60% !important;
                    padding-left: 2%;
                  }
                  .info-attribute-car{
                    display: none;
                  }
                  .option-delete{
                    width: 30% !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    //padding-right: 2%;
                    padding: 10px;
                  }
                  .options{
                    width: 40%;
                    .icon-option{
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
          }
        }
        .car-pagination{
          display: none;
        }
      }

    }
  }
  //.container-center{
  //  .panel-filter{
  //    height: auto;
  //    padding: 20px 0;;
  //  }
  //}
}

@media screen and (max-width: 1270px){
  .body-edit-car{
    .image-cover{
      height: 23px;
    }
  }
  .inventory-content{
    .container-header .right-container .inventory-pagination{
      display: none;
    }
    .container-center .body-car-inventory .car-pagination{
      display: none;
      width: 100%;
      padding-right: 0;
      padding-bottom: 30px;
      @include item-center;
      .pagination-left{
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1200px){
  .car-info-inventory .images-right-inventory .upload-image-logo .container-images{
    .label-upload {
      padding-top: 1px;
      font-size: 12px;
    }
    .label-upload-logo {
      font-size: 12px;
    }
    .label-upload-file{
      display: none;
    }
    .label-upload-mobile{
      color: #2194f1;
      font-weight: 500;
      padding-top: 10px;
      cursor: pointer;
      display: flex;
    }
  }
  .container-header{
    .container-filters{
      .panel-filter{
        .rowFilter{
          .col-left, .col-middle{
            width: calc(48.5% - 53px);
          }
          .col-right{
            width: 106px;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 11600px){
  .inventory-content{
    .container-center{
      .header-status{
        padding: 20px 15px 0 15px;
      }
      .container-cars-grid{
        padding: 20px 5px 0 15px;
      }
    }
  }

  .inventory-content .body-car-inventory .container-cars div
  {
    .header-status{
      padding: 20px 15px 0 15px;
    }
    .container-cars-grid{
      padding: 20px 5px 0 15px;
    }
  }
}

@media screen and (max-width: 1150px){
  .inventory-content{
    .container-center{
      .body-car-inventory{
        .container-cars{
          .container-cars-grid{
            .photoList{
              div{
                .col-sm-12{
                  #inventoryPhotoDropTarget{
                    .car-image-column{
                      .bottom-car{
                        .info-attribute-car{
                          width: 45%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }
}

@media screen and (max-width: 1024px){
  .body-edit-car{
    .label-upload-logo {
      display: none;
    }
  }
  .modal-deactivate{
    .modal-dialog{
      width: 70%;
    }
  }

}

@media screen and (max-width: 1000px){
  .body-edit-car{
    .image-cover{
      height: 19px;
    }
  }

  .car-info-inventory .images-right-inventory .upload-image-logo .container-images{
    .label-upload {
      padding-top: 0;
    }
  }
}

@media screen and (max-width: 950px){
  .container-header{
    .container-filters{
      .panel-filter{
        .rowFilter{
          .col-left{
            margin: 0;
            width: 66.1%;
            .box-left{
              margin: 0;
              padding-right: 1%;
            }
            .box-right{
              padding-left: 1%;
              margin: 0;
            }
          }
          .col-right{
            margin-left: calc(33.8% - 105.75px);
          }
          .col-middle{
            margin: 0;
            width: 66.1%;
            .box-left{
              margin: 0;
              padding-right: 1%;
            }
            .box-right{
              padding-left: 1%;
              margin: 0;
            }
          }
        }
        .second-row-filter{
          padding-top: 1%;
        }
      }
    }
  }
  .box-edit-car .car-documents .body-documents .col-100 {
    margin-bottom: 20px;
    .upload-left {
      width: 50%;
    }
  }
}

@media screen and (max-width: 860px){
  .body-edit-car .image-cover {
    font-size: 11px;
  }
}

@media screen and (max-width: 768px){
  .container-header{
    left: 0 !important;
    height: 40px;
    .left-container{
      .action{
        padding-left: 15px;
        padding-top: 8px;
        svg{
          width: 33px;
          height: 36px;
        }
        .icon-add-task {
          width: 32px;
        }
      }
      height: inherit;
      .arrow-back{
        margin-right: 10px;
      }
      .title{
        width: auto;
        font-size: 3vw;
      }
      .subtitle{
        @include one-single-line-text;
        width: calc(90% - 67px);
        margin: 0;
        padding: 0 30px 0 10px;
        font-size: 3vw;
      }
    }
    .right-container{
      height: inherit;
      .inventory-pagination{
        display: block !important;
        padding-right: 0;
      }
      .export-inventory{
        display: none;
      }
      .option-edit{
        display: none;
      }
      .mode-view {
        #buttonOption{
          margin-bottom: 15px;
        }
      }
    }
  }

  #editHeader{
    .left-container{
      width: 100%;
    }
  }

  .body-edit-car{
    .box-edit-car{
      .applicant-section-header{
        display: flex;
        margin-left: 10px;
        .applicant-section-header-icon{
          width: 25px;
          height: 25px;
        }
        .applicant-section-header-info{
          display: flex;
          align-items: center;
        }
      }
      .car-cost{
        .body-car-cost-info{
          .body-cost{
            padding: 15px;
            .body-cost-header{
              display: none;
            }

            .body-cost-fields{
              flex-direction: column;

              .info-column-tit-desc{
                .info-title{
                  padding-left: 0 !important;
                  input{
                    width: 100%;
                  }
                  .title-input{
                    display: block;
                    color: $icon-arrow-color;
                    font-weight: 400;
                    font-size: 11px;
                    padding-left: 10px;
                  }
                }
              }

              .info-column{
                padding-top: 10px;
                .info-conf{
                  justify-content: space-between;
                  .info-price{
                    margin-right: 0;
                    width: calc(33.3% - 30px) !important;
                    .title-input{
                      display: block;
                      color: $icon-arrow-color;
                      font-weight: 400;
                      font-size: 11px;
                      padding-left: 10px;
                    }
                    input{
                      width: 100%;
                      padding-left: 16px;
                      //text-align: end;
                    }
                    .currencyinput{
                      //margin-top: 11px;
                      margin: 11px 7px;
                    }
                  }
                  .info-icon{
                    @include item-center;
                    width: auto;
                    margin-left: 0 !important;
                    .dropzone-container{
                      @include item-center;
                      height: 100%;
                    }
                    .icon-action-content-delete{
                      margin-left: 0 !important;
                    }
                  }
                  .container-drop-zone{
                    margin-top: 7px !important;
                  }
                }
              }
            }

            .footer-cost{
              padding-right: 15px;
              .add-cost{
                flex: inherit;
              }
              .sub-total-cost{
                margin-left: 0;
                justify-content: end;
                padding: 2px 0 2px 2px !important;
                .sub-total{
                  margin: 0;
                  justify-content: end;
                }
              }
            }
          }
        }
      }
    }
    .col12 {
      width: 50%;
    }
  }

  #containerHeaderGeneral{
    .right-container{
      .right-container-no-edit{
        .list-view{
          padding-right: 10px;
        }
        #buttonOption{
          padding-right: 12px;
          .icon-option{
            div>svg{
              margin-left: 5px;
              margin-top: 7px;
            }
          }
        }
      }
    }
    .container-filters{
      padding: 10px;
      .panel-filter{
        padding: 0;
        .rowFilter{
          padding-left: 0;
          .col-left{
            width: 100%;
            margin: 0;
            .box-left{
              width: 100%;
              margin-right: 0;
              .category-status{
                float: left;
                overflow-x: auto !important;
                .container-pills{
                  width: calc(100% - 20px);
                  float: left;
                  overflow-x: auto;
                  white-space: nowrap;
                  display: flex;
                }
                .pill-container{
                  width: auto;
                  flex-wrap: nowrap;
                  .label-value{
                    padding: 5px;
                    position: initial;
                  }
                }
              }
            }
            .box-right{
              display: none;
            }
          }
          .col-middle{
            display: none;
          }
          .col-right{
            display: none;
          }
        }
        .row-filters-expanded{
          display: none;
        }
      }

      .popover-box-category{
        width: calc(100% - 20px) !important;
        left: 10px !important;
        top: 88px !important;
      }
    }
  }

  .inventory-content{
    .container-header {
      .right-container {
        .mode-view {
          #buttonOption{
            svg{
              height: 18px ;
            }
          }
        }
      }
    }
    .container-center{
      padding: 55px 10px 10px 10px;
      .body-car-inventory{
        .container-filters{
          //height: 0;
          .panel-filter{
            padding: 5px 0 15px 0;
            .rowFilter{
              display: none;
              .select-categories{
                #selectedModels{
                  display: none;
                }
              }
            }
            .row-filters-expanded{
              display: none;
            }
            @mixin row-second-filters-mobile(){
              padding: 10px 0 0 10px !important;
              @include row;
              .category{
                display: block;
                width: 33.3%;
                margin-right: 10px;
                border: 1px solid #ccc;
                border-radius: 4px;
                height: 31px;
                padding: 4px 1px 5px 7px;
                label{
                  font-size: 11px;
                  color: #4D4F5C;
                  font-weight: $font-weight-regular;
                  display: inline-block;
                  max-width: 100%;
                  margin-bottom: 5px;
                }
                .icon-arrow-content{
                  padding: 1px;
                  float: right;
                  svg{
                    height: 10px;
                    fill: #A4AFB7;
                  }
                }
              }

              .category-disabled{
                display: block;
                border: 1px solid #ccc;
                width: 33.3%;
                margin-right: 1%;
                border-radius: 4px;
                height: 31px;
                padding: 4px 1px 5px 7px;
                label{
                  color: #ccc !important;
                  font-weight: $font-weight-regular;
                }
                .icon-arrow-content{
                  height: 10px;
                  fill: #ccc !important;
                  padding: 1px;
                  float: right;
                  svg{
                    height: 10px;
                    fill: #A4AFB7;
                  }
                }
              }
            }
            .row-first-filters-mobile{
              @include row-second-filters-mobile;
            }
            .row-second-filters-mobile{
              @include row-second-filters-mobile;
            }
            .row-clean-filter{
              @include row-second-filters-mobile;
              padding: 10px 10px 0 10px  !important;
              .category,.category-disabled{
                width: 47%;
                margin-right: 10px;
              }
              @include row;
            }
          }

        }
        .container-cars{
          .header-status{
            padding: 20px 10px 0 10px;
          }
          .container-cars-grid{
            padding: 5px;
            .photoList{
              div{
                .col-sm-12{
                  padding: 0;
                  #inventoryPhotoDropTarget{
                    float:left;
                    width: 50%;
                    padding: 5px;
                  }
                }
                .car-image-row{
                  .bottom-car{
                    .options{
                      display: block;
                      .icon-option{
                        svg{
                          fill: #d9dce3;
                        }
                      }
                    }
                    &:hover, .active{
                      .options{
                        .icon-option{
                          svg{
                            fill: $button-color-red
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .box-edit-car{
    .car-info{
      .body-car-info{
        .col25 {
          width: 50%;
        }
      }
    }
    .body-car-mechanical{
      .col20{
        width: 33.33%;
      }
      .col-sec-sub{
        .col75{
          width: 100%;
        }
      }
    }
    .car-pricing{
      .body-car-pricing-info {
        .col25 {
          width: 33.33%;
        }
      }
    }
  }
  .body-edit-car{
    .images-left-inventory{
      float: left;
      width: 100%;
      padding: 0 31px 10px 15px;
    }
    .images-right-inventory{
      float: left;
      width: 100%;
      padding: 10px 20px 10px 15px;
    }
    .image-cover{
      height: 20px;
      font-size: 13px;
    }
    .label-upload-logo {
      display: none;
    }
    .save-mobile-view{
      display: flex;
    }
  }

  .body-car-inventory{
    .body-car-header{
      display: none;
    }
    .container-cars{
      border-radius: 5px;
      .table-cars{
        .table{
          thead{
            display: none;
          }
          .tr-list-view-mobile{
            border-bottom: 1px solid #D7DAE2;
            border-top: 1px solid #D7DAE2;
            td{
              padding: 10px 0;
            }
            &:hover, .active{
              cursor: pointer;
            }
          }
          .container-car-list-view{
            display: flex;
            align-items: center;
            .container-vertical-line{
              width: 10px;
              float: left;
              .vertical-line-car{
                width: 4px;
                height: 30px;
                border-radius: 0 4px 4px 0;
              }
            }
            .container-image-car{
              width: 100px;
              height: 65px;
              img{
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .container-attributes-car{
              display: grid;
              width: calc(100% - 180px);
              padding: 0 10px;
              .title{
                font-size: 3.8vw;
                font-weight: 700;
                color: $text-color;
                @include one-single-line-text;
              }
              .second-row-attributes{
                font-size: 2.7vw;
                span{
                  color: $text-color;
                }
              }
              .container-prices{
                font-size: 2.7vw;
                color: $text-color-table;
                .suggested-price{
                  padding-right: 10px;
                  color: $green-color;
                }
                .retail-price{
                  color: $text-color;
                }
              }
            }
            .options{
              display: flex;
              width: 50px;
              margin: 0 10px 0 0;
              .icon-copy, .icon-delete{
                svg{
                  fill: #d9dce3;;
                }
              }
              .icon-copy{
                &:hover, active{
                  svg{
                    fill: $light-blue-color-2;
                  }
                }
              }
              .icon-delete{
                &:hover, .active{
                  svg{
                    fill: $button-color-red;
                  }
                }
              }
            }
          }
        }
      }
    }
    .car-pagination{
      padding: 15px 0 !important;
    }
  }

  .modal-deactivate{
    .modal-dialog{
      width: 90% !important;
    }
  }

  .modal-info{

    .modal-dialog{
      width: 100%;
      padding: 20px;
      height: auto;
      .modal-body{
        .img-car-info{
          width: 100%;
          height: auto;
        }
      }
    }
  }

}

@media only screen and (min-width: 768px) and (min-height: 375px) and (max-height: 1024px) and (max-width: 1280px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  .right-container {
    .inventory-pagination {
      display: block !important;
      padding-right: 0;
      .dp-pagination-container{
        .pagination-right{
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 620px){
  .box-edit-car{
    .body-car-mechanical{
      .col20{
        width: 50%;
      }
    }
    .car-pricing{
      .body-car-pricing-info {
        .col25 {
          width: 50%;
        }
      }
      .col-sec-sub{
        .col33{
          width: 50%;
        }
      }
    }
    .car-header {
      flex-wrap: wrap;
    }
  }

  .body-car-inventory{
    .container-cars{
      .table-cars{
        .table{
          .container-car-list-view{
            .container-image-car{
              width: 80px;
              height: 52px;
            }
            .container-attributes-car{
              width: calc(100% - 90px);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 530px){
  .body-edit-car{
    .image-cover{
      height: 15px;
      font-size: 11px;
    }
  }
}

@media screen and (max-width: 500px){
  .body-edit-car{
    padding: 71px 15px 15px 15px;
  }
  .box-edit-car{

    .col12 {
      width: 100%;
    }
    .car-info{
      .body-car-info{
        .col25 {
          width: 100%;
        }
      }
    }
    .body-car-mechanical{
      .col20{
        width: 100%;
      }
    }
    .car-pricing{
      .body-car-pricing-info {
        .col25 {
          width: 100%;
        }
      }
      .col-sec-sub{
        .col33{
          width: 100%;
        }
      }
    }
    .car-image-target {
      width: 33.33%;
    }
    .images-left-inventory {
      padding: 0 26px 10px 10px;
    }
    .images-right-inventory{
      padding: 10px 16px 10px 10px;
    }
    .car-documents .body-documents .col-100 {
      margin-bottom: 20px;
      .upload-left,.upload-right {
        width: 100%;
      }
    }

    .panel-body, .body-cost{
      padding: 15px 10px !important;
    }

  }

  .car-info-inventory .images-right-inventory .uploaded-images .image-box{
    width: 33.33% !important;
  }

  .body-car-inventory{
    .container-cars{
      .table-cars{
        .table{
          .container-car-list-view{
            .container-image-car{
              width: 60px;
              height: 39px;
            }
            .container-attributes-car{
              width: calc(100% - 70px);
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 420px){
  .body-edit-car{
    .image-cover{
      height: 12px;
      font-size: 10px;
    }
  }

  .body-car-inventory{
    .container-cars{
      .table-cars{
        .table{
          .container-car-list-view{
            .container-image-car{
              width: 50px;
              height: 32.5px;
            }
            .container-attributes-car{
              width: calc(100% - 60px);
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 400px){
  .box-edit-car{
    .car-image-target {
      width: 50%;
    }
    .uploaded-images .image-box {
      width: 50%;
    }
  }
  .body-edit-car {
    padding: 80px 10px 0 10px;
  }

  .inventory-content {
    .container-center {
      .body-car-inventory {
        .container-cars {
          .container-cars-grid {
            .photoList {
              div {
                .col-sm-12 {
                  #inventoryPhotoDropTarget {
                    float: left;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }

    }
  }

  .container-header{
    .left-container{
      .arrow-back{
        margin-left: 10px;
      }
      .arrow-right{
        font-size: 4vw;
      }
      .subtitle{
        padding: 0 10px 0 10px;
      }
    }
  }

  .car-info-inventory .images-right-inventory .uploaded-images .image-box {
    width: 50% !important;
  }

}
.box-edit-car {
  #containerCost {
    .cost-container {
      grid-template-columns: 1fr auto auto auto auto;
      .footer-cost .add-cost {
        grid-column: 1/3;
      }

      .body-cost-fields:first-child .info-icon .icon-arrow-content {
        margin-top: 6px;
        width: 16px;
        padding: 0;
        float: none;
      }

      .body-cost-fields:first-child .info-icon {
        margin-top: 10px !important;
      }

      .body-cost-fields:first-child .dropzone-container {
        margin-top: 5px;
      }

      .body-cost-fields {
        .info-icon {
          width: 16px;
          margin-top: 0 !important;

          .icon-action-content-delete {
            width: 16px !important;
          }

          .icon-arrow-content{
            margin-top: 2px;
            width: 16px;
            padding: 0;
            float: none;
          }
        }

        .dropzone-container {
          margin-top: -5px;
        }

        .container-drop-zone{
          width: 100px;
        }
      }
      .footer-cost .label-sub-total{
        margin-right: 10px;
        text-align: right;
        padding-top: 8px;
      }
    }
    .body-cost-fields:first-child .currencyinput {
      margin-top: 30px;
    }
    .body-cost-header {
      display: none;
    }
  }
}
